import type React from 'react'
import './SubscriptionCard.scss'

const PLACEHOLDER_IMAGE_SRC = 'https://placehold.it/100x100'

type SubscriptionCardProps = {
  imageSrc: string
  isCancelled?: boolean
  notice: React.ReactNode
  title: string
  info: React.ReactNode
  extraInfo: React.ReactNode
  button: React.ReactNode
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  imageSrc,
  isCancelled = false,
  notice,
  title,
  info,
  extraInfo,
  button,
}) => {
  const classes = `c-subscription-card ${isCancelled ? 'is-cancelled' : ''}`

  return (
    <div className={classes}>
      {notice}
      <div className="c-subscription-card__wrapper">
        <div className="c-subscription-card__overview">
          <img
            src={imageSrc || PLACEHOLDER_IMAGE_SRC}
            alt="Subscription"
            className="c-subscription-card__image"
            loading="lazy"
          />
          <div className="c-subscription-card__overview-content">
            <h2 className="c-subscription-card__title type-h6">{title}</h2>
            <p className="c-subscription-card__info">{info}</p>
          </div>
        </div>
        <div className="c-subscription-card__actions">
          <div className="c-subscription-card__extra-info type-body">
            {extraInfo}
          </div>
          {button}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard
