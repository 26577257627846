import dayjs from 'dayjs'
import type { FC } from 'react'
import { Fragment } from 'react'

import useNextWeekMenu from '@/hooks/useNextWeekMenu'
import type { CMSNWMpage } from '@/types/sanity'

import './next-week-menu-flyout.scss'

interface NectWeekMenuFlyoutProps {
  nextweekmenu?: CMSNWMpage
  displayStickyCTA?: boolean
}

const NextWeekMenuFlyOut: FC<NectWeekMenuFlyoutProps> = ({
  nextweekmenu,
  displayStickyCTA = true,
}) => {
  const { currentWeekMenuTabItem, isLoading, isMenuLoading } = useNextWeekMenu()

  if (isLoading || isMenuLoading) {
    return <div style={{ margin: '0 auto' }}> Loading...</div>
  }

  const scrollToBuyingPanel = () => {
    const header = document.querySelector('.header') as HTMLElement
    const buyingPanelHeader = document.querySelector(
      '.program-panel-header',
    ) as HTMLElement

    const flyoutLinks = nextweekmenu?.flyout?.links
    const selectedLink = flyoutLinks?.find((link) =>
      window.location.pathname.match((link.link as any).external),
    )

    if (buyingPanelHeader && header && selectedLink) {
      const multiplyingFactor =
        window.scrollY > buyingPanelHeader.offsetTop ? 2 : 1

      window.scroll({
        top:
          buyingPanelHeader.offsetTop - multiplyingFactor * header.offsetHeight,
        behavior: 'smooth',
      })
    } else {
      window.location.href = '/nutrition/signature-nutrition-program'
    }

    const button = document.querySelector('.modal__close-button')
    button?.dispatchEvent(new Event('click'))
  }

  return (
    <div className="fly-out-week-wrapper">
      <div className="fly-out-week-inner-wrapper">
        <h4>{`Week of ${dayjs(currentWeekMenuTabItem.date).format(
          'MMMM D',
        )}`}</h4>
        {currentWeekMenuTabItem.menu.map((dayData) => {
          const { date, meals } = dayData
          const _dateDay = dayjs(date).format('dddd')
          return (
            <Fragment key={_dateDay}>
              <div className="fly-out-week-inner-wrapper__weekday">
                <h4>{_dateDay}</h4>
                <div className="fly-out-week-inner-wrapper__weekday__day">
                  {meals.map((meal) => {
                    const { dayImageUrl: image, description, title } = meal
                    return (
                      <div
                        key={meal.id}
                        className={
                          'fly-out-week-inner-wrapper__weekday__day__card'
                        }
                      >
                        <div className="fly-out-week-inner-wrapper__weekday__day__card__image">
                          <img src={image} alt={title} />
                        </div>
                        <div className="fly-out-week-inner-wrapper__weekday__day__card__content">
                          {meal.new && (
                            <div className="fly-out-week-inner-wrapper__weekday__day__card__content__new">
                              NEW MEAL
                            </div>
                          )}
                          <label className="fly-out-week-inner-wrapper__weekday__day__card__content__title">
                            {title}
                          </label>
                          <label className="fly-out-week-inner-wrapper__weekday__day__card__content__description">
                            {description}
                          </label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
      {displayStickyCTA && (
        <div className="fly-out-week-inner-wrapper__footer">
          <div
            onClick={scrollToBuyingPanel}
            className="fly-out-week-inner-wrapper__footer__button"
          >
            Sign Up Now
          </div>
        </div>
      )}
    </div>
  )
}

export default NextWeekMenuFlyOut
