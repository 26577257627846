import React, { useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { $isStickyCTAVisible } from '@/stores/ui/stores'
import Icon from '../Icon/Icon'

import './Kustomer.scss'
import type { CMSLiveChat } from '@/types/sanity'
import dayjs from 'dayjs'
import moduleEnv from '@/constants/env'

const KUSTOMER_LOAD_DELAY = 5000
const KUSTOMER_URL = 'https://cdn.kustomerapp.com/chat-web/widget.js'

type KustomerFormProps = {
  kustomerSettings: CMSLiveChat
}

type WorkingHour = null | [number, number][]

type WorkingHours = {
  0: WorkingHour
  1: WorkingHour
  2: WorkingHour
  3: WorkingHour
  4: WorkingHour
  5: WorkingHour
  6: WorkingHour
}

type WorkingHoursKeys = 0 | 1 | 2 | 3 | 4 | 5 | 6

type Attributes = {
  name: string
  hours: WorkingHours
  createdAt: Date
  modifiedAt: Date
  updatedAt: Date
  timezone: string
  default: boolean
}

type KustomerSchedulesResponse = {
  id: string
  attributes: Attributes
  links: object
  relationships: object
  type: 'schedule'
}

const KustomerForm: React.FC<KustomerFormProps> = ({ kustomerSettings }) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const [isAgentsOnline, setIsAgentsOnline] = useState(false)
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  const isStickyVisible = useStore($isStickyCTAVisible)

  const openForm = () => {
    setIsFormVisible(true)
  }

  const closeForm = () => {
    setIsFormVisible(false)
  }

  const loadScript = () => {
    if (!document.querySelector(`script[src="${KUSTOMER_URL}"]`)) {
      var script = document.createElement('script')
      script.src = KUSTOMER_URL

      script.setAttribute(
        'data-kustomer-api-key',
        moduleEnv.PUBLIC_KUSTOMER_API_KEY,
      )
      script.onload = () => {
        const event = new Event('kustomerLoaded')
        window.dispatchEvent(event)
      }
      window.document.body.appendChild(script)
    }
  }

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()
    closeForm()
    if (window.Kustomer) {
      window.Kustomer.createConversation()
    } else {
      console.error('Kustomer is not available.')
    }
  }

  const isOnline = (data?: KustomerSchedulesResponse) => {
    if (!data || !data.attributes || !data.attributes.hours) {
      return false
    }

    const currentTime = dayjs().tz('America/New_York')
    const dayOfWeek = currentTime.day() as WorkingHoursKeys
    const hour = currentTime.hour()
    const minute = currentTime.minute()
    const totalMinutes = hour * 60 + minute

    if (!data.attributes.hours[dayOfWeek]) return false

    for (const interval of data.attributes.hours[dayOfWeek]!) {
      if (
        totalMinutes >= Number(interval[0]) &&
        totalMinutes <= Number(interval[1])
      ) {
        return true
      }
    }

    return false
  }

  const checkAgentsOnline = async () => {
    try {
      const response = await fetch('/api/kustomer-schedules', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = (await response.json()).data as KustomerSchedulesResponse[]

      isOnline(
        data.find((el) => el.id === moduleEnv.PUBLIC_ACTIVE_WORKING_HOURS_ID),
      )
        ? setIsAgentsOnline(true)
        : setIsAgentsOnline(false)
    } catch (error) {
      console.error('Error fetching Kustomer schedules:', error)
    }
  }

  useEffect(() => {
    if (kustomerSettings.displayChatButton) {
      window.addEventListener('kustomerLoaded', function () {
        window.Kustomer.start({
          brandId: moduleEnv.PUBLIC_KUSTOMER_BRAND_ID,
          hideChatIcon: true,
          hideHistory: true,
          lang: 'en',
        })
      })
      checkAgentsOnline()
      setTimeout(() => {
        loadScript()
        setIsScriptLoaded(true)
      }, KUSTOMER_LOAD_DELAY)
    }
  }, [])

  useEffect(() => {
    if (isScriptLoaded) {
      setIsButtonVisible(isAgentsOnline)
    }
  }, [isScriptLoaded])

  const imageSrc =
    'https://cdn.shopify.com/s/files/1/0192/0076/files/chat-icon.png'

  return (
    <>
      {isButtonVisible && (
        <button
          className={`c-kustomer-form__button type-label ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
          onClick={openForm}
        >
          Chat
          <div style={{ position: 'relative' }}>
            <img
              src={imageSrc}
              alt="Chat bot"
              className="c-kustomer-form__chat-icon"
            ></img>
            {isAgentsOnline && (
              <div className="c-kustomer-form__chat-online-icon"></div>
            )}
          </div>
        </button>
      )}
      {isFormVisible && (
        <div
          className={`c-kustomer-form__form ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
        >
          <div className="c-kustomer-form__header">
            <p className="c-kustomer-form__title type-label">
              CHAT WITH SUPPORT
            </p>
            <button
              className="c-kustomer-form__disclaimer-close"
              onClick={closeForm}
              onKeyUp={closeForm}
            >
              <Icon name="cross" />
            </button>
          </div>
          <form className="c-kustomer-form__content" onSubmit={submitForm}>
            <p className="c-kustomer-form__content-header type-label">
              Disclaimer
            </p>
            <p className="c-kustomer-form__content-text c-kustomer-form__content-text--subheader type-body">
              By using this chat, you:
            </p>
            <ol type="1">
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                understand that an automated chatbot will collect and process
                any information you provide, and you may request to speak with a
                live agent during normal business hours (9 AM - 5PM ET).
              </li>
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                understand that any information you provide may be monitored and
                retained, including by our service providers.
              </li>
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                are accepting and consenting to the practices described in our{' '}
                <a href="/pages/privacy-policy">Privacy Policy.</a>
              </li>
            </ol>
            <button
              type="submit"
              className="button button--tertiary c-kustomer-form__submit-button"
            >
              Get Started
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default KustomerForm
